<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>
    <br /><br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper mt-2 mb-2 mt-md-0 mb-md-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <!-- <div class="p-relative">
        <button
          type="button"
          class="btn mulai  font-bold"
          @click="buatUser"
        >
          <fa-icon icon="plus-circle" class="mr-1" /> BUAT USER
        </button>
      </div> -->
    </div>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleDetail(row.item)"
        >
          <fa-icon icon="pencil-alt" class="mr-1" /> Detail
        </b-button>

        <!-- <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-if="row.item.published"
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="minus-circle" class="mr-1" /> Unpublish
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-else
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="circle" class="mr-1" /> Publish
        </b-button> -->
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-modal
      v-model="modalDetail"
      size="md"
      title="Detail pemesanan"
      centered
    >
      <label>Produk</label>
      <p v-for="(prod, idx) in currentDetail" :key="idx">
        {{ idx+1 }}. {{ prod.name }} ({{ prod.quantity }} pcs) 
      </p>
      
      <template v-slot:modal-footer>
        <b-button
          variant="primary"
          size="medium"
          class="float-right"
          @click="modalDetail = false"
        >
          OK
        </b-button>
      </template>
      <br />
      <br />
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      currentDetail: [],
      currentId: null,
      isBusy: true,
      selectId: null,
      items: [], 
      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "shop_name",
          label: "Nama Toko",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "created_at",
          label: "Created_at",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "status",
          label: "Status"
        },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      showPopup: false,
      role:[],
      modalDetail:false
    };
  },

  mounted() {
    this.totalRows = this.items.length;
    this.orderList();
  },

  methods: {
    ...mapActions({
      getOrderList: types.GET_ORDER_LIST,
      getOrderDetail: types.GET_ORDER_DETAIL
    }),
    handleDetail(item) {
      this.currentId=item.id;
      this.currentDetail=[];
      this.getOrderDetail({id:this.currentId})
        .then(response => {
          this.modalDetail = true;
          this.currentDetail = response;
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        })
    },
    orderList(level_id = null) {
      this.isBusy = true;
      this.getOrderList()
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            if(res[i].status == 'created') {
              var status = 'Dibuat';
            } else if(res[i].status == 'confirmed') {
              var status = 'Dikonfirmasi';
            } else if(res[i].status == 'paid') {
              var status = 'Dibayar';
            } else if(res[i].status == 'shipped') {
              var status = 'Dikirim';
            } else if(res[i].status == 'done') {
              var status = 'Diterima';
            }
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              shop_name: res[i].shop_name,
              name: res[i].name,
              status: status,
              created_at: this.formatDate(res[i].created_at)
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    formatDate(strDate) {
      var date = new Date(strDate);
      var yyyy = date.getFullYear();
      var mm = String(date.getMonth()+1).padStart(2, "0");
      var dd = String(date.getDate()).padStart(2, "0");
      var hh = String(date.getHours()).padStart(2, "0");
      var ii = String(date.getMinutes()).padStart(2, "0");
      var ss = String(date.getSeconds()).padStart(2, "0");

      date = yyyy + "-" + mm + "-" + dd + " " + hh + ":" + ii + ":" + ss;

      return date;
    },
    toastSuccess(toaster, append = false) {
      this.$bvToast.toast('Data updated', {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 1000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 1000
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // buatUser() {
    //   this.$router.push("/user/add");
    // }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}

.product-popup {
  position: absolute;
  z-index: 99;
  right: 0;
  top: -88px;
  width: 316px;
  height: 70px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f08045;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  display: flex;
}

.product-popup__item {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #494949;
  flex-direction: column;
}

.product-popup__item:hover {
  background-color: #ffe1d2;
}

.product-popup-arrow {
  position: absolute;
  z-index: 999;
  right: 99px;
  top: -19px;
  width: 14px;
}

.search-wrappper {
  min-width: 160px;
}

.pub-unpub {
  min-width: 105px;
}
</style>
